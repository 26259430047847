import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { defineAsyncComponent } from 'vue';
import { availabeRole } from '@/helpers/access';
import { toastError } from '@/helpers/toastify';
import stateStore from "@/store";
import { beforeSave, checkBeforeCreating } from './api';

export default ({
	forms: {
		edit: {
			panel: {
				// height: '280px',
				width: '70%'
			},
			component: defineAsyncComponent(() => import('./Edit/Index.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./List/Index.vue'))
		},
		select: {
			panel: {
				width: '90%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					barcode: {
						description: 'Штрих-код исследования'
					},
					study: {},
					patient: {},
					notion: {
						config: {
							visible: false
						}
					}
				}
			}
		}
	},

	onBeforeDelete: async function (data: any) {
		if (availabeRole(['admin']) ? true : data.author_dep == stateStore.state.user.department) {
			return true;
		} else {
			Swal.fire('Внимание', 'У Вас недостаточно прав для удаления данного исследования!', 'warning');

			return false;
		}
	},

	async checkBeforeRegister(data: any) {
		const { status, performer, performer_dep, paid, cutting } = data;

		if (status == 2) data.date_reg = dayjs().format('YYYY-MM-DD HH:mm:ss');

		if (data.date_reg) {
			for (const row of cutting) {
				if (row.nmb) {
					const response: any = await beforeSave({
						id: row.id,
						nmb: row.nmb,
						nmb_till: row.nmb_till,
						pieces: row.pieces,
						performer,
						performer_dep,
						paid,
						year: dayjs(data.date_reg).year()
					})

					if (response.complete) {
						return true;
					} else {
						toastError(response.message);
					}

					return false;
				}
			}
		}

		return true;
	},

	onBeforeSave: async function (data: any, isNew: boolean) {
		if (isNew) {
			const response: any = await checkBeforeCreating({
				patient: data.patient,
				date_collect: data.date_collect
			});

			if (!response.complete) {
				const date_collect = data.date_collect ? `с датой забора ${dayjs(data.date_collect).format('DD.MM.YYYY')}` : '';

				const { value } = await Swal.fire({
					title: `Исследование пациента ${data._patient} ${date_collect} уже существует. Продолжить?`,
					showCancelButton: true,
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена'
				});

				return value;
			}
		}

		return this.checkBeforeRegister(data);
	}
})